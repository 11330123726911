<template>
    <div
        @drop.prevent="input.actions.onFileListInput"
        @drop.stop="drop.actions.endDrag"
        @dragenter.prevent.stop="drop.actions.beginDrag"
        @dragover.prevent.stop="drop.actions.beginDrag"
        @dragleave.prevent.stop="drop.actions.endDrag"
        ref="file-drop-input"
        id="file-drop-input"
        class="group block max-w-screen-md min-w-18 mx-auto px-4 py-5 sm:p-6 sm:rounded-lg text-center font-medium"
        :class="[
            ...drop.classes.value.transition,
            ...drop.classes.value.text,
            ...drop.classes.value.background,
            ...drop.classes.value.outline,
            ...drop.classes.value.border,
        ]"
    >
        <div class="flex justify-center mt-1 text-center">
            <CloudUploadIcon
                class="h-10 w-10"
                :class="[
                    ...drop.classes.value.transition,
                    ...drop.classes.value.icon,
                ]"
            ></CloudUploadIcon>
        </div>
        <h3 class="mt-2 text-sm font-semibold">No files selected.</h3>
        <p class="mt-1 text-sm">
            {{ prompt }}
        </p>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPOSABLES -->
    import useFileDrop from '~CSVUploader/hooks/input/useFileDrop';
    import useFileInput from '~CSVUploader/hooks/input/useFileInput';

    // <!-- COMPONENTS -->
    import { CloudUploadIcon } from '@heroicons/vue/outline';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FileDropInput',
        components: {
            CloudUploadIcon,
        },
        props: {
            multiple: Boolean,
            prompt: {
                type: String,
                default: 'Get started by selecting a dataset file.',
            },
        },
        emits: ['input:files', 'alert'],
        setup(props, context) {
            // Reactive state.
            const state = { dragging: ref(false) };
            const drop = useFileDrop(context, state);
            const input = useFileInput(context);
            return {
                drop,
                input,
            };
        },
    });
</script>
