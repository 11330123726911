// <!-- API -->
import { unref } from 'vue';
import isNil from 'lodash-es/isNil';

/**
 * @class
 * Step content with reactive properties.
 */
export class StepContent {
    /**
     * Create with separate arguments.
     * @param {V.Ref<String> | V.ComputedRef<String>} [header]
     * @param {V.Ref<String> | V.ComputedRef<String>} [prompt]
     * @param {V.Ref<String> | V.ComputedRef<String>} [body]
     * @param {V.Ref<String> | V.ComputedRef<String>} [footer]
     */
    static fromRefs(header = null, prompt = null, body = null, footer = null) {
        return StepContent.create(
            unref(header),
            unref(prompt),
            unref(body),
            unref(footer)
        );
    }

    /**
     * Create with separate arguments.
     * @param {String} [header]
     * @param {String} [prompt]
     * @param {String} [body]
     * @param {String} [footer]
     */
    static create(header = null, prompt = null, body = null, footer = null) {
        const content = new StepContent({
            header: isNil(header) ? null : header,
            prompt: isNil(prompt) ? null : prompt,
            body: isNil(body) ? null : body,
            footer: isNil(footer) ? null : footer,
        });
        return content;
    }

    /**
     * Prepare step content for usage on a given step.
     * @param {Object} [props]
     * @param {String} [props.header]
     * @param {String} [props.prompt]
     * @param {String} [props.body]
     * @param {String} [props.footer]
     */
    constructor(props = {}) {
        const { header, prompt, body, footer } = props;
        /** @type {String} */
        this.header = header ?? 'Form Step Header';
        /** @type {String} */
        this.prompt =
            prompt ?? 'This is an example form step component prompt.';
        /** @type {String} */
        this.body = body ?? 'This is an example form step component body.';
        /** @type {String} */
        this.footer =
            footer ?? 'This is an example form step component footer.';
    }

    get data() {
        return {
            header: this.header,
            prompt: this.prompt,
            body: this.body,
            footer: this.footer,
        };
    }

    /**
     * Set the content ref.
     * @param {String} value
     */
    setHeader(value) {
        this.header = value;
        return this;
    }
    /**
     * Set the content ref.
     * @param {String} value
     */
    setPrompt(value) {
        this.prompt = value;
        return this;
    }
    /**
     * Set the content ref.
     * @param {String} value
     */
    setBody(value) {
        this.body = value;
        return this;
    }
    /**
     * Set the content ref.
     * @param {String} value
     */
    setFooter(value) {
        this.footer = value;
        return this;
    }
}

export default StepContent;
