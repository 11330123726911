// <!-- API -->
import { ref } from 'vue';

// <!-- COMPOSABLES -->
import useFileDrop from '~CSVUploader/hooks/input/useFileDrop';
import useFileInput from '~CSVUploader/hooks/input/useFileInput';
import useClearButtons from '~CSVUploader/hooks/input/useClearButtons';
import useFilePreviewGrid from '~CSVUploader/hooks/grid/useFilePreviewGrid';

/**
 * Handle reactivity for the file selector grid.
 */
export const useFileSelectPreview = () => {
    // STATE
    const useDragging = () => ({
        dragging: ref(false),
    });

    // EXPOSE
    return {
        useDragging,
        useClearButtons,
        useFileDrop,
        useFileInput,
        useFilePreviewGrid,
    };
};

export default useFileSelectPreview;
