// <!-- API -->
import { computed } from 'vue';

// <!-- TYPES -->
import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';

/**
 * Get the clear all and clear flagged buttons.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Object} state Local component state.
 * @param {V.Ref<Boolean>} state.dragging Is the user currently dragging?
 * @param {V.Ref<Map<String, UploadRecord>>} state.records
 * @param {V.Ref<Number>} state.mountKey Dependency for computed.
 */
export default function ({ emit }, { dragging, records, mountKey }) {
    // PROPERTIES
    const selectedFiles = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return [...records.value.values()].map((r) => r.file.value);
    });
    const flaggedRecords = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return [...records.value.values()].filter((r) => r.isMarkedForRemoval);
    });
    const hasRecords = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return !!selectedFiles.value.length;
    });
    const hasFlaggedRecords = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return !!flaggedRecords.value.length;
    });
    const isNotDragging = computed(() => {
        return !dragging.value;
    });

    // HELPERS.
    const useDangerVariant = (enabled) => (enabled ? 'danger' : 'white');

    // Remove flagged.
    const removeFlaggedLabel = computed(() => {
        // ts-ignore
        const _ = mountKey.value;
        return hasFlaggedRecords.value
            ? `Remove ${flaggedRecords.value.length} File(s)`
            : 'Select Files to Remove';
    });

    const removeFlagged = {
        theme: computed(() => useDangerVariant(removeFlagged.enable.value)),
        enable: computed(() => isNotDragging.value && hasFlaggedRecords.value),
        label: removeFlaggedLabel,
        onClick: (event) => {
            console.log('[remove::flagged]: Clicked.', event);
            emit('remove:flagged', event);
        },
    };

    // Define the clearAll behavior.
    const clearAll = {
        theme: computed(() => useDangerVariant(clearAll.enable.value)),
        enable: computed(() => isNotDragging.value && hasRecords.value),
        label: computed(() => 'Clear Files'),
        onClick: (event) => {
            console.log('[clear::all]: Clicked.', event);
            emit('clear:all', event);
        },
    };

    // Expose.
    return {
        isNotDragging,
        hasRecords,
        hasFlaggedRecords,
        clearAll,
        removeFlagged,
    };
}
