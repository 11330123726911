// <!-- COMPOSABLES -->
import { useFileList } from './useFileList';

/**
 * Get file input module.
 *
 * @param {V.SetupContext<any>} context Setup context.
 */
export default function ({ emit }) {
    /**
     * Get an array of distinct files.
     *
     * @param {Array.<File>} files Array of input files.
     */
    const getDistinctFiles = (files) => {
        const filenames = files.map((file) => file.name);
        const removed = [];
        const distinct = files.filter((file, index) => {
            if (filenames.indexOf(file.name) == index) {
                return true;
            }
            if (!removed.includes(file.name)) {
                removed.push(file.name);
            }
            return false;
        });
        return {
            removed,
            files: distinct,
        };
    };

    // ACTIONS
    // Safe to destructure.
    const actions = {
        /**
         * Handle file selection through a native dialog or from
         * a drag and drop ingestion flow.
         *
         * @param {(InputEvent | DragEvent)} event Input event with FileList.
         */
        parseFileListInput: async (event) => {
            // If DragEvent.
            if (event?.dataTransfer?.files) {
                return useFileList(event?.dataTransfer?.files).toArray();
            }
            // If InputEvent.
            // @ts-ignore
            if (event?.target?.files) {
                // @ts-ignore
                return useFileList(event?.target?.files).toArray();
            }
            // Return empty array if unhandled event type.
            throw new Error('No files input. Did they cancel the prompt?');
        },
        /**
         * Handle a FileList input event and
         * emit an event for an update or an alert.
         *
         * @emits input:files(File[])} Update input files.
         * @emits alert(Error) Signal an error.
         * @param {(InputEvent | DragEvent)} event Event payload.
         */
        onFileListInput: (event) => {
            actions
                .parseFileListInput(event)
                .then((files) => {
                    emit('input:files', getDistinctFiles(files));
                })
                .catch((reason) => {
                    console.log(reason);
                    console.log('Error.', event);
                    emit('alert', reason);
                });
        },
    };

    return {
        getDistinctFiles,
        actions,
    };
}
