// <!-- API -->
import { computed } from 'vue';
import { StepContent } from '~CSVUploader/hooks/workflow/useStepContent';

// <!-- TYPES -->
import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
import { UploadStore } from '../store/useUploadStore';

/** Detect the drag and drop feature. */
const detectDragAndDropFeature = () => {
    try {
        const span = document.createElement('span');
        return 'draggable' in span;
    } catch (Error) {
        return false;
    }
};

/** Is drag and drop enabled? */
const isDragAndDropEnabled = detectDragAndDropFeature();

/**
 * Get the content prompt.
 * @param {File[]} files
 * @param {Number} limit
 */
const getContentPrompt = (files, limit) => {
    if (!files || !files.length) {
        return 'File Preview';
    }
    return `Select up to ${limit} file(s).`;
};

/**
 * Get the content status.
 * @param {File[]} files
 * @param {Number} limit
 */
const getContentStatus = (files, limit) => {
    if (!files || !files.length) {
        return 'No files selected.';
    }
    return `${files.length} out of ${limit} file(s) selected.`;
};

/**
 * Handle reactivity for the file selector step.
 */
export const useFileSelect = () => {
    /**
     * Create a dynamic computed content object.
     * @param {UploadStore} store
     * @param {V.Ref<Number>} mountKey Dependency tracking variable.
     * @returns {V.ComputedRef<StepContent>}
     */
    const useContent = (store, mountKey) => {
        const $header = `File Select`;
        return computed(() => {
            // ts-ignore
            const _ = mountKey.value;
            const options = store.api.state.uploader.config;
            const records = store.api.state.uploader.data.records;
            const files = [...records.values()].map((r) => r.file.value);
            return StepContent.create()
                .setHeader($header)
                .setPrompt(getContentPrompt(files, options.fileCountLimit))
                .setFooter(getContentStatus(files, options.fileCountLimit));
        });
    };

    /**
     * Filter actions from the form.
     * @param {V.SetupContext<any, any>} context
     * @param {UploadFormConfig<any, any>} form
     */
    const useActions = (context, form) => {
        const $actions = form.data.useDataAction(context);
        return {
            inputFiles: $actions.input.files,
            dropAllRecords: $actions.drop.all,
            dropMarkedRecords: $actions.drop.whereMarkedForRemoval,
        };
    };

    // EXPOSE
    return {
        isDragAndDropEnabled,
        useContent,
        useActions,
    };
};

export default useFileSelect;
