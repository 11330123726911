// <!-- UTILITIES -->
import { collect } from 'collect.js';

/**
 * Cast FileList into an array or collection of Files.
 * @param {FileList} files
 */
export const useFileList = (files) => {
    // As array.
    const toArray = () => (!files.length ? [] : Array.from(files));
    const toCollection = () => collect(toArray());
    return {
        all: toArray,
        collect: toCollection,
        toArray,
        toCollection,
    };
};

// DEFAULT
export default useFileList;
