// <!-- API -->
import { computed } from 'vue';

const icon = {
    inactive: [
        'text-primary-700',
        'hover:text-gray-700',
        'hover:duration-300',
        'hover:animate-pulse',
        'group-hover:text-gray-800',
        'focus:text-gray-500',
    ],
    dragging: [
        'text-primary-700',
        'duration-300',
        'animate-pulse',
        'hover:duration-150',
        'hover:animate-bounce',
        'hover:text-secondary-500',
        'group-hover:text-secondary-500',
        'focus:text-secondary-500',
    ],
};

const text = {
    inactive: [
        'text-gray-500',
        'hover:text-gray-700',
        'group-hover:text-gray-800',
        'focus:text-gray-500',
    ],
    dragging: [
        'text-gray-600',
        'hover:text-secondary-700',
        'group-hover:text-secondary-700',
        'focus:text-secondary-700',
    ],
};

const background = {
    inactive: [
        'bg-gray-50',
        'hover:bg-gray-200',
        'focus:bg-gray-100',
        'focus-visible:bg-gray-100',
    ],
    dragging: [
        'bg-white',
        'hover:bg-primary-100',
        'focus:bg-primary-100',
        'focus-visible:bg-primary-100',
    ],
};

const outline = {
    inactive: [
        'focus:ring-1',
        'focus:ring-offset-2',
        'focus:ring-primary-700',
        'focus-visible:ring-primary-100',
    ],
    dragging: [
        'focus:ring-2',
        'focus:ring-offset-2',
        'focus:ring-secondary-400',
        'focus-visible:ring-primary-100',
    ],
};

const border = {
    inactive: [
        'border-2',
        'border-dashed',
        'hover:border-solid',
        'border-gray-300',
        'hover:border-gray-700',
        'group-hover:border-gray-700',
        'focus:border-gray-500',
        'focus-visible:border-gray-500',
        'focus-within:border-gray-500',
    ],
    dragging: [
        'border-2',
        'border-dashed',
        'hover:border-solid',
        'border-primary-300',
        'hover:border-primary-500',
        'group-hover:border-primary-500',
        'focus:border-primary-100',
        'focus-visible:border-primary-100',
        'focus-within:border-primary-100',
    ],
};

/**
 * Get file drop module.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Object} state State.
 * @param {V.Ref<Boolean>} state.dragging Reference to dragging property.
 */
// ts-ignore
export default function ({ emit }, { dragging }) {
    // GETTERS.
    const getters = {
        getCSS: (classes) => {
            return dragging?.value
                ? getters.getDraggingCSS(classes)
                : getters.getInactiveCSS(classes);
        },
        getInactiveCSS: (classes) => {
            return classes?.inactive ?? [];
        },
        getDraggingCSS: (classes) => {
            return classes?.dragging ?? [];
        },
    };

    /** Compute classes for the dropzone area. */
    const classes = computed(() => ({
        icon: getters.getCSS(icon),
        background: getters.getCSS(background),
        outline: getters.getCSS(outline),
        border: getters.getCSS(border),
        text: getters.getCSS(text),
        transition: ['ease-out duration-300 md:transition-colors'],
    }));

    // ACTIONS
    // Safe to destructure.
    const actions = {
        beginDrag: () => (dragging.value = true),
        endDrag: () => (dragging.value = false),
    };

    return {
        classes,
        actions,
        getters,
    };
}
