<template>
    <div class="align-middle sm:flex-shrink-0">
        <label
            for="file-select-input"
            :class="button.theme"
        >
            <PlusCircleIcon class="h-5 w-5 mr-1" />
            Add File
        </label>
        <input
            @change="input.actions.onFileListInput"
            type="file"
            :multiple="multiple"
            ref="file-select-input"
            id="file-select-input"
            name="file-select-input"
            class="w-0 overflow-hidden opacity-0"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { PlusCircleIcon } from '@heroicons/vue/outline';

    // <!-- COMPOSABLES -->
    import useButtonPresets from '@/components/buttons/hooks/useButtonPresets';
    import useFileInput from '~CSVUploader/hooks/input/useFileInput';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FileSelectInput',
        components: {
            PlusCircleIcon,
        },
        props: {
            multiple: {
                type: Boolean,
                default: true,
            },
        },
        emits: ['input:files', 'alert'],
        setup(props, context) {
            const {
                defaultLayouts,
                defaultStyles,
                primary,
                md,
                defaultModifiers,
            } = useButtonPresets();
            /** File select button. */
            const button = {
                theme: [
                    ...defaultLayouts,
                    ...defaultStyles,
                    ...primary,
                    ...md,
                    ...defaultModifiers,
                ],
            };

            /** File select input. */
            const input = useFileInput(context);
            return {
                button,
                input,
            };
        },
    });
</script>
