// <!-- API -->
import { ref, computed } from 'vue';

// <!-- UTILITIES -->
import { getFileType } from '@/utils/file';
import { Bases, Factors, FormatFileSize } from '@/utils/FormatFileSize';

// <!-- COMPOSABLES -->
import useAgGridVue from '@/hooks/useAgGridVue';

// <!-- TYPES -->
import { Store } from 'vuex';
import { UploadRecord } from '@/store/types/uploader/state';

/**
 * Get the file preview grid.
 *
 * @param {V.SetupContext<any>} context Setup context.
 * @param {Store} store
 * @param {Object} payload
 * @param {V.Ref<Map<String, UploadRecord>>} payload.records
 *
 */
export default function ({ emit }, { commit }, { records }) {
    /**
     * Selected files reference.
     */
    const selectedFiles = computed(() => {
        return [...records.value.values()].map((r) => r.file.value);
    });

    /**
     * Flagged files reference.
     */
    const flaggedRecords = computed(() => {
        return [...records.value.values()].filter((r) => r.isMarkedForRemoval);
    });

    /**
     * @param {String} filename
     * @param {Boolean} flagged
     */
    const updateFlagged = async (filename, flagged) => {
        /** Get the current flagged status. */
        const currentRecord = records.value.get(filename);
        const previousFlagged = currentRecord.isMarkedForRemoval;

        /** If the current flagged status is the same as flagged. */
        if (previousFlagged != flagged) {
            /** If the current flag is different, set the new flag. */
            commit('uploader/data/changeRecordFlag', {
                record: currentRecord,
                id: 'checked',
                value: flagged,
            });
        }
        return { record: currentRecord, previousFlagged };
    };

    /** Get grid API. */
    // ts-ignore
    const { defaultColDef, ...grid } = useAgGridVue();

    /** References. */
    const state = {
        domLayout: ref('autoHeight'),
        rowSelection: ref('multiple'),
        defaultColDef: ref(defaultColDef),
        columnDefs: ref([]),
        rowData: computed(() => actions.mapFilesToRowData(selectedFiles.value)),
        /** @type {V.Ref<GridApi>} */
        gridApi: ref(null),
        /** @type {V.Ref<ColumnApi>} */
        gridColumnApi: ref(null),
    };

    // HOOKS

    const useDomLayout = (layout) => {
        state.domLayout.value = layout;
    };

    const useDefaultColDef = (defaultDefs) => {
        state.defaultColDef.value = defaultDefs;
    };

    const useColumnDefs = (colDefs) => {
        state.columnDefs.value = colDefs;
    };

    const useGridApi = (api) => {
        state.gridApi.value = api;
    };

    const useGridColumnApi = (api) => {
        state.gridColumnApi.value = api;
    };

    // GETTERS
    const getters = {
        // Allows us to reorganize easily.
        /** @returns {Record<String, AgGrid.ColumnDef>} */
        getFilePreviewColumnDefs: () => ({
            action: {
                headerName: '',
                field: 'action',
                width: 50,
                pinned: true,
                sortable: false,
                resizable: false,
            },
            index: {
                headerName: '',
                field: 'index',
                width: 25,
                pinned: true,
                sortable: true,
                resizable: false,
                checkboxSelection: true,
                valueFormatter: () => '',
                cellClass: 'flex items-center',
            },
            name: {
                headerName: 'File Name',
                field: 'name',
                minWidth: 500,
                width: 550,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                cellClass: 'flex text-left items-center',
            },
            type: {
                headerName: 'File Type',
                field: 'type',
                valueGetter: getFileType,
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                cellClass: 'flex text-left items-center',
            },
            size: {
                headerName: 'File Size',
                field: 'size',
                minWidth: 80,
                resizable: true,
                sortable: true,
                suppressMenu: true,
                cellClass: 'flex text-left items-center',
            },
        }),
        getRowIdFromFileName: (data) => data.name,
    };

    /**
     * Flag files included in the filenames array.
     *
     * @param {Array.<String>} filenames Selected row filenames.
     */
    const useSelectedRows = async (filenames) => {
        console.groupCollapsed(
            `[update::flagged] (${
                filenames.length
            }) @ ${new Date().toLocaleTimeString()}`
        );
        const dispatches = selectedFiles.value.map((file) => {
            return updateFlagged(file.name, filenames.includes(file.name));
        });
        const results = await Promise.allSettled(dispatches);
        results.forEach((result) => console.log(result));
        console.groupEnd();
    };

    // ACTIONS
    const actions = {
        mapFilesToRowData: (files) => {
            return files.map((file, index) => ({
                index: index,
                name: file.name,
                type: file.type,
                size: FormatFileSize(file.size, Bases.BYTE, Factors.MEGABYTE),
            }));
        },
        updateColumnDefs: () => {
            const columnDefs = getters.getFilePreviewColumnDefs();
            useColumnDefs([
                columnDefs.index,
                columnDefs.name,
                columnDefs.type,
                columnDefs.size,
            ]);
        },
        // updateRowData: () => {
        //   const rowData = actions.mapFilesToRowData(selectedFiles.value);
        //  useRowData(rowData);
        // },
        onGridReady: (event) => {
            state.gridApi.value = event.api;
            state.gridColumnApi.value = event.columnApi;
            state.gridApi.value.addEventListener(
                `firstDataRendered`,
                actions.onSyncCheckedRecords
            );
            state.gridApi.value.addEventListener(
                `rowDataChanged`,
                actions.onSyncCheckedRecords
            );
        },
        onSyncCheckedRecords: async (event) => {
            if (state.rowData.value.length > 0) {
                state.gridApi.value.forEachNode((rowNode) => {
                    const filename = rowNode.data.name;
                    const flagged = flaggedRecords.value.map((r) => r.filename);
                    const isMarked = flagged.includes(filename);
                    rowNode.selectThisNode(isMarked);
                });
            }
            actions.onRowDataChanged(event);
        },
        onColumnResized: (event) => {
            // See: grid.onColumnResized(event);
            state.gridApi.value?.refreshCells();
            emit('resize:grid', event); // Currently unused.
        },
        // ts-ignore
        onRowDataChanged: (event) => {
            state.gridApi.value?.refreshCells();
        },
        // ts-ignore
        onSelectionChanged: (event) => {
            const selectedNodes = state.gridApi.value.getSelectedNodes();
            const selectedIds = selectedNodes.map((node) => node.data.name);
            useSelectedRows(selectedIds ?? []);
        },
    };

    return {
        // useRowData,
        useGridApi,
        useGridColumnApi,
        useSelectedRows,
        useDefaultColDef,
        useColumnDefs,
        useDomLayout,
        state,
        getters,
        actions,
    };
}
