<template>
    <div
        @drop.prevent="input.actions.onFileListInput"
        @drop.stop="drop.actions.endDrag"
        @dragenter.prevent.stop="drop.actions.beginDrag"
        @dragover.prevent.stop="drop.actions.beginDrag"
        @dragleave.prevent.stop="drop.actions.endDrag"
        ref="file-preview-input"
        id="file-preview-input"
    >
        <!-- TODO: Replace with FileDropInput. Shortcut to get functionality onto staging. -->
        <div
            v-if="!isSelectionFull && !isNotDragging"
            class="group"
            :class="[
                ...drop.classes.value.transition,
                ...drop.classes.value.text,
                ...drop.classes.value.background,
                ...drop.classes.value.outline,
                ...drop.classes.value.border,
            ]"
        >
            <CloudUploadIcon
                class="h-10 w-10"
                :class="[
                    ...drop.classes.value.transition,
                    ...drop.classes.value.icon,
                ]"
            />
            Drop More Files
        </div>
        <div class="mb-6 flex flex-row justify-between align-middle space-x-4">
            <ModalButton
                :key="mountKey(`remove-marked-files`)"
                :theme="removeFlagged.theme.value"
                id="remove-flagged"
                name="remove-flagged"
                class="inline-flex flex-shrink-0 group"
                :disabled="!removeFlagged.enable.value"
                @click="removeFlagged.onClick"
            >
                <MinusCircleIcon
                    class="h-4 w-4 text-white mr-1"
                    :class="{
                        'group-hover:text-danger': removeFlagged.enable.value,
                        hidden: !removeFlagged.enable.value,
                    }"
                />
                {{ removeFlagged.label.value }}
            </ModalButton>
            <ModalButton
                :key="mountKey(`remove-all-files`)"
                :theme="clearAll.theme.value"
                id="clear-all"
                name="clear-all"
                class="inline-flex flex-shrink-0 group"
                :disabled="!clearAll.enable.value"
                @click="clearAll.onClick"
            >
                <TrashIcon
                    class="h-4 w-4 text-white mr-1"
                    :class="{
                        'group-hover:text-danger': clearAll.enable.value,
                        'group-hover:text-gray-400': !clearAll.enable.value,
                    }"
                />
                {{ clearAll.label.value }}
            </ModalButton>
        </div>
        <AgGridVue
            class="ag-theme-alpine w-full block"
            style="min-height: 250px"
            :domLayout="grid.state.domLayout.value"
            :rowSelection="grid.state.rowSelection.value"
            :defaultColDef="grid.state.defaultColDef.value"
            :columnDefs="grid.state.columnDefs.value"
            :rowData="grid.state.rowData.value"
            @grid-ready="grid.actions.onGridReady"
            @column-resized="grid.actions.onColumnResized"
            @selection-changed="grid.actions.onSelectionChanged"
            @row-data-changed="grid.actions.onRowDataChanged"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import {
        TrashIcon,
        CloudUploadIcon,
        MinusCircleIcon,
    } from '@heroicons/vue/solid';
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- COMPOSABLES -->
    import { useFileSelectPreview } from '~CSVUploader/hooks/input/useFileSelectPreview';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';

    // <!-- TYPES -->

    /** @typedef {import('@/store/types/uploader/state/UploadRecord').UploadRecord} UploadRecord */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SelectFileGrid',
        components: {
            AgGridVue,
            CloudUploadIcon,
            MinusCircleIcon,
            TrashIcon,
            ModalButton,
        },
        props: {
            multiple: Boolean,
            prompt: {
                type: String,
                default: 'Select files to upload.',
            },
            records: {
                /** @type {V.PropType<Map<String, UploadRecord>>} */
                type: Object,
                required: true,
            },
            fileCountLimit: {
                /** @type {V.PropType<Number>} */
                type: Number,
                required: true,
            },
        },
        emits: [
            'change:rowData',
            'resize:grid',
            'input:files',
            'remove:flagged',
            'clear:all',
            'alert',
        ],
        setup(props, context) {
            const store = useStore();
            const { records, fileCountLimit } = toRefs(props);

            /** Local state. */
            const {
                useDragging,
                useClearButtons,
                useFileDrop,
                useFileInput,
                useFilePreviewGrid,
            } = useFileSelectPreview();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            /** Dragging tracker. */
            const { dragging } = useDragging();

            /** Get the buttons. */
            const {
                isNotDragging,
                hasRecords,
                hasFlaggedRecords,
                removeFlagged,
                clearAll,
            } = useClearButtons(context, { dragging, records, mountKey });

            // Get the file drop module.
            const drop = useFileDrop(context, { dragging });

            // Get the file input module.
            const input = useFileInput(context);

            // Get the file grid module.
            const grid = useFilePreviewGrid(context, store, { records });

            /**
             * Is the file selection set full?
             */
            const isSelectionFull = computed(() => {
                return records.value.size >= fileCountLimit.value;
            });

            /** Configure the grid module. */
            grid.useDefaultColDef({
                flex: 1,
                minWidth: 50,
                width: 100,
                resizable: true,
                sortable: true,
                filter: false,
            });
            grid.actions.updateColumnDefs();

            // LIFECYCLE (Mount)

            subscribeWatchedActions(store, [`*`]);
            subscribeWatchedMutations(store, [`*`]);

            return {
                drop,
                input,
                grid,
                mountKey: getNamespacedMountKey,

                isSelectionFull,
                isNotDragging,
                hasRecords,
                hasFlaggedRecords,

                removeFlagged,
                clearAll,
            };
        },
    });
</script>
