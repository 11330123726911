<template>
    <Panel class="mt-4">
        <template #header>
            <div class="flex flex-row">
                <h3
                    class="inline-flex flex-grow align-middle items-center float-left text-xl text-primary-700 col-span-3"
                >
                    {{ content.prompt }}
                </h3>
                <LoadingWrapper :isLoading="isLoading">
                    <FileSelectInput
                        :form="form"
                        :disabled="!isFileSelectionAllowed"
                        @input:files="inputFiles"
                        @alert="$emit('alert', $event)"
                    />
                </LoadingWrapper>
            </div>
        </template>
        <template #default>
            <LoadingWrapper :isLoading="isLoading">
                <FileDropInput
                    v-if="isFileDropzoneVisible"
                    :form="form"
                    @input:files="inputFiles"
                    @alert="$emit('alert', $event)"
                />
                <SelectFileGrid
                    v-if="!isSelectionEmpty"
                    :records="records"
                    :fileCountLimit="fileCountLimit"
                    @input:files="inputFiles"
                    @remove:flagged="dropMarkedRecords"
                    @clear:all="dropAllRecords"
                    @alert="$emit('alert', $event)"
                />
                <DebugFrame
                    :key="mountKey(`file-select-debug`)"
                    id="generic"
                    :startHidden="frame.startHidden"
                    :debug="frame.isEnabled"
                    :data="frame.data"
                />
            </LoadingWrapper>
        </template>
        <template #footer>
            <p class="text-center">{{ content.footer }}</p>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        toRefs,
        onMounted,
        onUnmounted,
    } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import FileDropInput from '~CSVUploader/components/inputs/FileDropInput.vue';
    import FileSelectInput from '~CSVUploader/components/inputs/FileSelectInput.vue';
    import SelectFileGrid from '~CSVUploader/components/grids/SelectFileGrid.vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useMounted } from '@vueuse/core';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';
    import { useFileSelect } from '~CSVUploader/hooks/input/useFileSelect';

    // <!-- TYPES -->
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
    import { FormNavigationPolicy } from '@/hooks/useNavigationPolicies';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FileSelectorStep',
        components: {
            Panel,
            FileDropInput,
            FileSelectInput,
            SelectFileGrid,
            LoadingWrapper,
            DebugFrame,
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig<any, any>>} */
                type: Object,
                required: true,
            },
        },
        emits: [
            'update:selectedFiles',
            'change:selectedFiles',
            'alert',
            'set:policies',
        ],
        setup(props, context) {
            // PROPS
            const { form } = toRefs(props);
            const { store } = form.value;
            const { isDragAndDropEnabled, useContent, useActions } =
                useFileSelect();

            // COMPOSABLES

            /**
             * Get the component isMounted tracker
             * for use with the loading components.
             */
            const isMounted = useMounted();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            // Get the dynamic content.
            const content = useContent(store, mountKey);

            // COMPUTED PROPERTIES

            /**
             * Prepare a loading wrapper conditional.
             */
            const isLoading = computed(() => {
                const _ = mountKey.value;
                const isNotReady = isMounted.value !== true;
                return isNotReady;
            });

            /**
             * Is the file selection set full?
             */
            const isSelectionFull = computed(() => {
                const _ = mountKey.value;
                const options = store.api.state.uploader.config;
                const records = store.api.state.uploader.data.records;
                return records.size >= options.fileCountLimit;
            });

            /**
             * Is the file selection set empty?
             */
            const isSelectionEmpty = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                return !records || records.size <= 0;
            });

            /**
             * Is file selection allowed?
             */
            const isFileSelectionAllowed = computed(() => {
                const _ = mountKey.value;
                return !isLoading.value && !isSelectionFull.value;
            });

            /**
             * Is the file dropzone visible?
             */
            const isFileDropzoneVisible = computed(() => {
                const _ = mountKey.value;
                return (
                    isDragAndDropEnabled &&
                    !isSelectionFull.value &&
                    isSelectionEmpty.value
                );
            });

            /** @type {V.ComputedRef<Map<String, UploadRecord>>} */
            const records = computed(() => {
                const _ = mountKey.value;
                return store.api.state.uploader.data.records;
            });

            /** @type {V.ComputedRef<Number>} */
            const fileCountLimit = computed(() => {
                const _ = mountKey.value;
                return store.api.state.uploader.config.fileCountLimit;
            });

            // ACTIONS

            const { inputFiles, dropAllRecords, dropMarkedRecords } =
                useActions(context, form.value);

            // NAVIGATION POLICIES

            const isSubmit = {
                visible: true,
                allowed: computed(() => {
                    const _ = mountKey.value;
                    return !isLoading.value && !isSelectionEmpty.value;
                }),
            };

            const submit = FormNavigationPolicy.prepare()
                .type.asSubmit()
                .label.exact('Next')
                .visible.exact(isSubmit.visible)
                .enabled.withComputed(isSubmit.allowed)
                .action.onClick(async () => {
                    console.dir(isSubmit);
                    await store.goToNextStep(isSubmit.allowed.value);
                })
                .create();

            const isReset = {
                visible: false,
                allowed: false,
            };

            const reset = FormNavigationPolicy.prepare()
                .type.asReset()
                .label.exact('Reset')
                .visible.exact(isReset.visible)
                .enabled.exact(isReset.allowed)
                .action.onClick(() => {
                    return false;
                })
                .create();

            // LIFECYCLE (Mount)

            subscribeWatchedActions(store.api.store, [`*`]);
            subscribeWatchedMutations(store.api.store, [`*`]);

            onMounted(() => {
                context.emit('set:policies', { submit, reset });
            });

            // LIFECYCLE (Unmount)

            onUnmounted(() => {
                isMounted.value = false;
                resetMountKey();
            });

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const options = store.api.state.uploader.config;
                    const records = store.api.state.uploader.data.records;
                    const selectedRecords = [...records.values()].map((r) => {
                        const {
                            flags,
                            status,
                            file,
                            location,
                            suggestedLocation,
                            mappingProfile,
                            suggestedMappingProfile,
                            batch,
                        } = r ?? {};
                        const { name, size, type } = file.value ?? {};
                        return {
                            id: name,
                            file: {
                                name,
                                type,
                                size: `${size} bytes`,
                            },
                            flags: [...flags].join(','),
                            status: [...status].join(','),
                            location: {
                                ...location,
                                suggested: suggestedLocation,
                            },
                            profile: {
                                ...mappingProfile,
                                suggested: suggestedMappingProfile,
                            },
                            batch,
                        };
                    });

                    // Create current step structure.
                    const { id, label, description } =
                        store.api.state.uploader.workflow.currentStep;
                    const currentStep = {
                        id,
                        label,
                        description,
                    };

                    // Prepare data.
                    const data = [
                        DebugObject.create('Current Step', currentStep),
                        DebugObject.create('Configuration', options),
                        ...selectedRecords.map((r) =>
                            DebugObject.create(`Record`, r)
                        ),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({
                        startHidden: true,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            /** Expose to the Options API. */
            return {
                // REACTIVITY
                isLoading,
                isMounted,
                mountKey: getNamespacedMountKey,

                // PROPS
                records,
                fileCountLimit,

                // DEBUG
                frame,

                // STEP CONTENT
                content,

                // STEP CONDITIONALS
                isSelectionEmpty,
                isFileSelectionAllowed,
                isFileDropzoneVisible,

                // STEP ACTIONS
                inputFiles,
                dropAllRecords,
                dropMarkedRecords,
            };
        },
    });
</script>
